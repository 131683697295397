import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'; // Import your Vuex store
import LoginComponent from '../components/LoginComponent.vue';
import RegisterComponent from '../components/RegisterComponent.vue';
import LogoutButton from '../components/LogoutButton.vue';
import DashboardComponent from '../components/DashboardComponent.vue';
import LearnerDashboard from '../components/learner/LearnerDashboard.vue';
import CoursesTable from '../components/CoursesTable.vue';
import AddCourse from '../components/AddCourse.vue';
import EditCourse from '@/components/EditCourse.vue';
import MyCourses from '../components/learner/MyCourses.vue';
import MyProgress from '../components/learner/MyProgress.vue';
import MyBadges from '../components/learner/MyBadges.vue';
import CourseCatalog from '../components/learner/CourseCatalog.vue';
import ViewCourse from '../components/learner/ViewCourse.vue'; 
import CoursePage from '../components/learner/CoursePage.vue'; 
import AdminUsers from '../components/Admin/AdminUsers.vue'; 
import CreateUser from '../components/Admin/CreateUser.vue'; 
import UserGroups from '../components/Admin/UserGroups.vue'; 
import CreateGroup from '@/components/Admin/CreateGroup.vue'; 
import ViewGroup from '@/components/Admin/ViewGroup.vue';
import EditGroup from '@/components/Admin/EditGroup.vue';
import EditUser from '@/components/Admin/EditUser.vue';
import ViewBranch from '@/components/Admin/ViewBranch.vue';
import AdminBranches from '@/components/Admin/AdminBranches.vue';
import CreateBranch from '@/components/Admin/CreateBranch.vue';
import EditBranch from '@/components/Admin/EditBranch.vue';
import UserCertificates from '@/components/learner/UserCertificates.vue';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardComponent,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/courses',
    name: 'Courses',
    component: CoursesTable,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/addcourse',
    name: 'Add Courses',
    component: AddCourse,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/courses/:id/edit',
    name: 'EditCourse',
    component: EditCourse,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/adduser',
    name: 'CreateUser',
    component: CreateUser,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/edituser/:id', // :id to capture user ID
    name: 'EditUser',
    component: EditUser,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
    },  
  {
    path: '/users',
    name: 'AdminUsers',
    component: AdminUsers,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/groups',
    name: 'UserGroups',
    component: UserGroups,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/groups/create',
    name: 'CreateGroup',
    component: CreateGroup,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/groups/:id',
    name: 'ViewGroup',
    component: ViewGroup,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/groups/:id/edit',
    name: 'EditGroup',
    component: EditGroup,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/branches',
    name: 'AdminBranches',
    component: AdminBranches,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/branches/create',
    name: 'CreateBranch',
    component: CreateBranch,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/branches/:id',
    name: 'ViewBranch',
    component: ViewBranch,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/branches/:id/edit',
    name: 'EditBranch',
    component: EditBranch,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner'] }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginComponent,
    meta: { requiresAuth: false }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutButton,
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterComponent,
    meta: { requiresAuth: false }
  },
  // Learner-specific routes
  {
    path: '/learner/dashboard',
    name: 'Learner Dashboard',
    component: LearnerDashboard,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
  {
    path: '/learner/courses',
    name: 'My Courses',
    component: MyCourses,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
  {
    path: '/learner/progress',
    name: 'My Progress',
    component: MyProgress,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
  {
    path: '/learner/badges',
    name: 'My Badges',
    component: MyBadges,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
  {
    path: '/learner/course-catalog',
    name: 'Course Catalog',
    component: CourseCatalog,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
  {
    path: '/learner/catalog/course/:id', // Dynamic route for viewing a specific course
    name: 'ViewCourse',
    component: ViewCourse,
    props: true, // Allows route parameters to be passed as props to the component
    meta: { requiresAuth: true, roles: ['learner'] } // Ensure the user is authenticated and is a learner
  },
  {
    path: '/learner/course/:course/unit/:unit_order?',
    name: 'course',
    component: CoursePage,
    props: true, 
    meta: { requiresAuth: true, roles: ['learner'] } 
  },
  {
    path: '/learner/certificates',
    name: 'User Certificates',
    component: UserCertificates,
    meta: { requiresAuth: true, roles: ['learner'] }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Use createWebHistory for Vue 3
  routes,
});


router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const allowedRoles = to.meta.roles;

  // Detect subdomain and fetch branch data if not yet in the store
  const host = window.location.host;
  let branchUrl = null;
  if (host.includes('localhost')) {
    const subdomainMatch = host.match(/^(.+?)\.localhost/);
    branchUrl = subdomainMatch ? subdomainMatch[1] : null;
  }else if (host.includes('jamlms')) {
      const subdomainMatch = host.match(/^(.+?)\.jamlms/);
      branchUrl = subdomainMatch ? subdomainMatch[1] : null;
  } else {
    const parts = host.split('.');
    branchUrl = parts.length > 2 ? parts[0] : null;
  }

  if (branchUrl && !store.state.branch) {
    await store.dispatch('fetchBranch', branchUrl); // Fetch branch data based on subdomain
  }

  // Proceed with authentication check
  if (requiresAuth && !token) {
    localStorage.setItem('intendedRoute', to.fullPath);
    next('/login');
  } else if (requiresAuth && token) {
    try {
      await store.dispatch('fetchUser'); // Fetch user info
      const userRole = store.state.user.role;

      if (allowedRoles && !allowedRoles.includes(userRole)) {
        next('/login'); // Unauthorized role, redirect to login
      } else {
        next(); // Authorized, proceed to route
      }
    } catch (error) {
      localStorage.removeItem('token'); // Clear token if error occurs
      next('/login');
    }
  } else {
    next(); // No auth required, proceed to route
  }
});


export default router;
