<template>
    <!-- User overview -->
    <CardHeader :title="title" font="lg" class="mb-7"></CardHeader>
    <div class="grid grid-cols-3 gap-4 mb-7">
      <div class="col-span-2">
        <!-- Donut Chart -->
        <div id="donut-chart" v-if="chartData.length > 0"></div>
        <p v-else class="text-center text-gray-600 dark:text-gray-400">
          No data available for the chart.
        </p>
      </div>
      <div>
        <dl class="max-w-md text-gray-900">
          <div class="flex flex-col pb-3">
            <dt class="text-gray-500 md:text-lg dark:text-gray-400 border-b pb-2 mb-2 font-semibold">
              Active users ({{ activeUsers }} in total)
            </dt>
            <dd class="text-3xl font-semibold">
              {{ activeUsers > 0 ? activeUsers + " active" : "No active users" }}
            </dd>
          </div>
          <div class="flex flex-col py-3">
            <dt class="text-gray-500 md:text-lg dark:text-gray-400 border-b pb-2 mb-2 font-semibold">
              CPD time logged
            </dt>
            <dd class="text-3xl font-semibold">
              {{ cpdTime > 0 ? cpdTime + " Hrs" : "No CPD time logged" }}
            </dd>
          </div>
          <div class="flex flex-col pt-3">
            <dt class="text-gray-500 md:text-lg dark:text-gray-400 border-b pb-2 mb-2 font-semibold">
              Certificates issued
            </dt>
            <dd class="text-3xl font-semibold">
              {{ certificatesIssued > 0 ? certificatesIssued : "No certificates issued" }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </template>
  
  <script>
  import CardHeader from "@/partials/CardHeader.vue";
  import ApexCharts from "apexcharts";
  
  export default {
    components: {
      CardHeader,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      chartData: {
        type: Array,
        required: true,
        default: () => [],
      },
      chartLabels: {
        type: Array,
        required: true,
        default: () => [],
      },
      activeUsers: {
        type: Number,
        default: 0,
      },
      cpdTime: {
        type: Number,
        default: 0,
      },
      certificatesIssued: {
        type: Number,
        default: 0,
      },
    },
    mounted() {
      if (this.chartData.length > 0) {
        this.renderChart();
      }
    },
    watch: {
      chartData: {
        immediate: true,
        handler(newData) {
          if (newData.length > 0) {
            this.renderChart();
          }
        },
      },
    },
    methods: {
      getChartOptions() {
        return {
          series: this.chartData,
          colors: ["#1C64F2", "#16BDCA", "#FDBA8C", "#E74694"],
          chart: {
            height: 420,
            width: "100%",
            type: "donut",
          },
          stroke: {
            colors: ["transparent"],
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontFamily: "Inter, sans-serif",
                    offsetY: 20,
                  },
                  total: {
                    showAlways: true,
                    show: true,
                    label: "Total",
                    fontFamily: "Inter, sans-serif",
                    formatter: () => {
                      return this.chartData.reduce((a, b) => a + b, 0);
                    },
                  },
                },
                size: "70%",
              },
            },
          },
          labels: this.chartLabels,
          legend: {
            position: "bottom",
            fontFamily: "Inter, sans-serif",
          },
        };
      },
      renderChart() {
        const chartContainer = document.getElementById("donut-chart");
        if (chartContainer && typeof ApexCharts !== "undefined") {
          const chart = new ApexCharts(chartContainer, this.getChartOptions());
          chart.render();
        }
      },
    },
  };
  </script>
  