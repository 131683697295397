<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
      <TopNav></TopNav>
  
      <!-- Sidebar -->
      <SideMenu></SideMenu>
  
      <main class="p-4 md:ml-64 h-auto pt-20">
        <!-- grid 1 -->
        <div class="flex flex-row mb-4 mt-3">
          <!-- Main content area with variable grid layout -->
          <div class="basis-full lg:basis-10/12 mx-auto max-w-screen-3xl pr-5">
            <!-- Responsive grid layout -->
            <div class="mt-4 grid grid-cols-12 gap-4 md:grid-cols-12 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
              
              <!-- User Activity Analytics -->
              <div class="col-span-5 md:col-span-3 xl:col-span-12 2xl:col-span-5 rounded-sm border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
                <UserActivityAnalytics
                title="How am I doing?"
                :chartData="[150, 223, 52, 35]" 
                :chartLabels="['Completed', 'Assigned', 'Expired', 'Inactive']"
                :activeUsers="10"
                :cpdTime="10"
                :certificatesIssued="15"
              />
                            </div>
  
              <!-- Recent Activities -->
              <div class="col-span-5 md:col-span-12 xl:col-span-12 2xl:col-span-3 rounded-sm border border-stroke bg-white p-7.5 pb-2 shadow-default dark:border-strokedark dark:bg-boxdark">
                <RecentActivities :activities="activities" :alerts="alerts" />
              </div>
  
              <!-- Quick Links -->
              <div class="col-span-5 md:col-span-12 xl:col-span-12 2xl:col-span-4 rounded-sm border border-stroke bg-white p-7.5 pb-2 shadow-default dark:border-strokedark dark:bg-boxdark">
                <QuickLinks title="Quick Links"></QuickLinks>
              </div>
  
              <!-- Course Overview -->
              <div class="col-span-5 md:col-span-3 xl:col-span-12 2xl:col-span-5  rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
                <CourseOverview
                title="Recent Courses"
                :courses="recentCourses"
              />
                          </div>
  
              <!-- Recent Users -->
              <div class="col-span-5 md:col-span-3 xl:col-span-12 2xl:col-span-5  rounded-sm border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
                <BasicUserTable
                title="Recent Users"
                :users="recentUsers"
              />
            </div>
            </div>
          </div>
          
          <!-- Sidebar on the right (account management section) -->
          <div class="hidden lg:block lg:basis-2/12 border-l pl-4 pt-5">
            <h3 class="font-semibold text-lg">Your Account</h3>
            <AccountManager title="Account Manager"></AccountManager>
            <CreditsSubscriptions title="Credits & Subscriptions"></CreditsSubscriptions>
            <TrainingURL title="Your 'Training suite URL'"></TrainingURL>
            <FAQList></FAQList>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>
  import TopNav from '@/components/headers/TopNav.vue';
  import SideMenu from '@/components/SideMenu.vue';
  import AccountManager from '@/components/dashboard/AccountManager.vue';
  import BasicUserTable from '@/components/dashboard/BasicUserTable.vue';
  import CreditsSubscriptions from '@/components/dashboard/CreditsSubscriptions.vue';
  import FAQList from '@/components/dashboard/FAQList.vue';
  import RecentActivities from '@/components/dashboard/RecentActivities.vue';
  import TrainingURL from '@/components/dashboard/TrainingURL.vue';
  import UserActivityAnalytics from '@/components/dashboard/UserActivityAnalytics.vue';
  import QuickLinks from '@/components/dashboard/QuickLinks.vue';
  import CourseOverview from '@/components/dashboard/CourseOverview.vue';
  import axios from 'axios';
  
  export default {
    components: {
      TopNav,
      SideMenu,
      AccountManager,
      BasicUserTable,
      CreditsSubscriptions,
      FAQList,
      RecentActivities,
      TrainingURL,
      UserActivityAnalytics,
      QuickLinks,
      CourseOverview,
    },
    data() {
      return {
        analyticsData: null,
        recentActivities: [],
        activities: [
  {
    description: 'Nick Mark mentioned Sara Smith in a new post',
    link: '#',
    iconBgColor: 'bg-indigo-500',
    iconColor: 'text-indigo-50',
    iconPath: 'M18 10c-4.4 0-8 3.1-8 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7z',
  },
],

alerts: [
  {
    description: 'Bill Bobbins completed the course "How to write .NET"',
    link: '#',
    iconBgColor: 'bg-green-500',
    iconColor: 'text-light-blue-50',
    iconPath: 'M23 11v2.085c-2.841...',
  },
],

        quickLinks: [],
        accountData: null,
        subscriptions: [],
        trainingURL: '',
        faqList: [],
        recentCourses: [
      { id: 1, name: "How to write Python code", users: 26 },
      { id: 2, name: "How to write .NET", users: 52 },
      { id: 3, name: "Become a coding pro", users: 21 },
      { id: 4, name: "Health and Safety at work", users: 66 },
      { id: 5, name: "Python basics", users: 12 },
    ],
    recentUsers: [
      { id: 1, name: "Neil Sims", email: "neil.sims@flowbite.com", type: "Student", courses: 9, expiredCourses: 0 },
      { id: 2, name: "Bonnie Green", email: "bonnie@flowbite.com", type: "Student", courses: 9, expiredCourses: 1 },
      { id: 3, name: "Jese Leos", email: "jese@flowbite.com", type: "Instructor", courses: 25, expiredCourses: 0 },
      { id: 4, name: "Thomas Lean", email: "thomas@flowbite.com", type: "Student", courses: 5, expiredCourses: 0 },
      { id: 5, name: "Leslie Livingston", email: "leslie@flowbite.com", type: "Student", courses: 0, expiredCourses: 5 },
    ],
      };
    },
    methods: {
      async fetchDashboardData() {
        try {
          // Fetch all necessary data in parallel
          const [
            analyticsResponse,
            activitiesResponse,
            linksResponse,
            coursesResponse,
            usersResponse,
            accountResponse,
            subscriptionsResponse,
            faqsResponse,
          ] = await Promise.all([
            axios.get('/api/analytics'),
            axios.get('/api/recent-activities'),
            axios.get('/api/quick-links'),
            axios.get('/api/recent-courses'),
            axios.get('/api/recent-users'),
            axios.get('/api/account-data'),
            axios.get('/api/subscriptions'),
            axios.get('/api/faqs'),
          ]);
  
          // Assign fetched data to component data
          this.analyticsData = analyticsResponse.data;
          this.recentActivities = activitiesResponse.data;
          this.quickLinks = linksResponse.data;
          this.recentCourses = coursesResponse.data;
          this.recentUsers = usersResponse.data;
          this.accountData = accountResponse.data;
          this.subscriptions = subscriptionsResponse.data;
          this.trainingURL = accountResponse.data.trainingURL;
          this.faqList = faqsResponse.data;
        } catch (error) {
          console.error('Error fetching dashboard data:', error);
        }
      },
    },
    created() {
      this.fetchDashboardData();
    },
  };
  </script>