<template>
    <CardHeader :title="title" font="lg" class="col-span-2"></CardHeader>
  
    <div class="relative overflow-x-auto px-3 mt-5">
      <table v-if="courses.length > 0" class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-base text-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
              Course Name
            </th>
            <th scope="col" class="px-6 py-3">
              Users
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="course in courses"
            :key="course.id"
            class="border-b border-gray-200 dark:border-gray-700"
          >
            <th
              scope="row"
              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800"
            >
              <a :href="'/courses/' + course.id" class="text-blue-700">{{ course.name }}</a>
            </th>
            <td class="px-6 py-4">
              {{ course.users }}
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else class="text-center text-gray-600 dark:text-gray-400 p-4">No recent courses available.</p>
      <div class="self-center text-center mt-3 mb-3">
        <a href="/courses" class="text-blue-700 flex items-center justify-center">
          <span class="inline-block">Manage all courses</span>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m10 16 4-4-4-4"
            />
          </svg>
        </a>
      </div>
    </div>
  </template>
  
  <script>
  import CardHeader from "@/partials/CardHeader.vue";
  
  export default {
    components: {
      CardHeader,
    },
    props: {
      title: String,
      courses: {
        type: Array,
        default: () => [],
      },
    },
  };
  </script>
  