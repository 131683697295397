<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
      <TopNav />
      <SideMenu />
  
      <main class="p-6 md:ml-64 h-auto pt-20">
        <section class="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
          <div class="mx-auto max-w-screen-3xl px-4 lg:px-12">
            <div class="dark:bg-gray-800 relative sm:rounded-lg overflow-hidden">
              <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                <div class="w-full max-w-md">
                  <form class="flex items-center">
                    <label for="branch-search" class="sr-only">Search Branches</label>
                    <div class="relative w-full">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                          <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="branch-search"
                        v-model="searchQuery"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Search branches"
                        @input="fetchBranches"
                      />
                    </div>
                  </form>
                </div>
                <div class="flex items-center">
                  <router-link :to="{ name: 'CreateBranch' }" class="flex items-center justify-center text-white !bg-primary-700 hover:!bg-primary-800 focus:ring-4 !focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2">
                    + Add Branch
                  </router-link>
                </div>
              </div>
  
              <div v-if="loading" class="text-center py-10">
                <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
  
              <div v-if="!loading && branches.length" class="overflow-x-auto">
                <table class="w-full text-md text-left text-gray-500 dark:text-gray-400 bg-white shadow-md mt-5">
                  <thead class="text-md text-gray-700 bg-gray-200">
                    <tr>
                      <th class="px-4 py-3">Name</th>
                      <th class="px-4 py-3">URL</th>
                      <th class="px-4 py-3">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="branch in filteredBranches"
                      :key="branch.id"
                      class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <td class="px-4 py-3">
                        <router-link :to="{ name: 'ViewBranch', params: { id: branch.id } }" class="text-primary-600 dark:text-primary-300 hover:underline">
                          {{ branch.name }}
                        </router-link>
                      </td>
                      <td class="px-4 py-3">{{ branch.url || 'N/A' }}</td>
                      <td class="px-4 py-3">
                        <button @click="confirmDeleteBranch(branch.id)" class="text-red-600 hover:underline">Delete</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
  
              <div v-if="!loading && !branches.length" class="text-center text-gray-600 dark:text-gray-400 py-10">
                <h2 class="text-3xl font-semibold mb-4">No Branches Found</h2>
                <p class="text-lg">Try adjusting your search or add a new branch.</p>
              </div>
            </div>
          </div>
        </section>
  
        <!-- Confirmation Popup -->
        <div v-if="showDeleteModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-md w-full">
            <h2 class="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-4">Confirm Deletion</h2>
            <p class="text-gray-600 dark:text-gray-400 mb-6">Are you sure you want to delete this branch? This action cannot be undone.</p>
            <div class="flex justify-end space-x-4">
              <button @click="showDeleteModal = false" class="px-4 py-2 bg-gray-500 text-white rounded-lg">Cancel</button>
              <button @click="deleteBranch" class="px-4 py-2 bg-red-600 text-white rounded-lg">Delete</button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import TopNav from '@/components/headers/TopNav.vue';
  import SideMenu from '@/components/SideMenu.vue';
  
  export default {
    components: { TopNav, SideMenu },
    data() {
      return {
        branches: [],
        searchQuery: '',
        loading: true,
        showDeleteModal: false,
        branchIdToDelete: null,
      };
    },
    computed: {
      filteredBranches() {
        const query = this.searchQuery.toLowerCase();
        return this.branches.filter(branch =>
          branch.name.toLowerCase().includes(query) || (branch.url || '').toLowerCase().includes(query)
        );
      },
    },
    methods: {
      async fetchBranches() {
        this.loading = true;
        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
            query: `
              query {
                branches {
                  id
                  name
                  url
                }
              }
            `,
          });
          this.branches = response.data.data.branches || [];
        } catch (error) {
          console.error('Error fetching branches:', error);
        } finally {
          this.loading = false;
        }
      },
      confirmDeleteBranch(branchId) {
        this.branchIdToDelete = branchId;
        this.showDeleteModal = true;
      },
      async deleteBranch() {
        try {
          await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
            query: `
              mutation ($branch_id: Int!) {
                DeleteBranch(branch_id: $branch_id) {
                  id
                  name
                }
              }
            `,
            variables: { branch_id: parseInt(this.branchIdToDelete) },
          });
          this.branches = this.branches.filter(branch => branch.id !== this.branchIdToDelete);
        } catch (error) {
          console.error("Error deleting branch:", error);
        } finally {
          this.showDeleteModal = false;
          this.branchIdToDelete = null;
        }
      },
    },
    created() {
      this.fetchBranches();
    },
  };
  </script>
  