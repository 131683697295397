import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state: {
    user: null,
    branch: null, // Branch data
  },
  mutations: {
    setUser(state, userData) {
      state.user = userData;
    },
    clearUser(state) {
      state.user = null;
    },
    setBranch(state, branchData) {
      state.branch = branchData;
    },
    clearBranch(state) {
      state.branch = null;
    },
  },
  actions: {
    async fetchUser({ commit }) {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/user`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          commit('setUser', response.data);
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      }
    },
    async fetchBranch({ commit }, branchUrl) {
      try {
        // Send a GraphQL query to fetch branch data by branchUrl
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query ($branchUrl: String!) {
              branch(url: $branchUrl) {
                name
                url
                logo_url
                primary_color
                secondary_color
              }
            }
          `,
          variables: {
            branchUrl: branchUrl,
          },
        });

        // Check for errors in the GraphQL response
        if (response.data.errors) {
          console.error('GraphQL error:', response.data.errors);
          return;
        }

        // Commit the fetched branch data to the Vuex store
        const branchData = response.data.data.branch;
        commit('setBranch', branchData);

      } catch (error) {
        console.error('Failed to fetch branch:', error);
      }
    },
    clearUser({ commit }) {
      commit('clearUser');
      commit('clearBranch');
    },
  },
  getters: {
    getUser: state => state.user,
    isAuthenticated: state => !!state.user,
    getBranch: state => state.branch, // Getter for branch data
  }
});

export default store;
