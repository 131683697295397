<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
    <TopNav />
    <SideMenu />

    <main class="p-6 md:ml-64 h-auto pt-20 flex justify-center">
      <section class="w-full max-w-lg dark:bg-gray-800 rounded-lg p-8">
        <div class="mb-6 flex justify-between items-center">
          <h2 class="text-3xl font-semibold text-gray-800 dark:text-gray-100">Edit Branch</h2>
          <router-link :to="{ name: 'ViewBranch', params: { id: branch.id } }" class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span class="ml-2">Back</span>
          </router-link>
        </div>

        <div v-if="loading" class="flex justify-center py-10">
          <svg aria-hidden="true" class="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
        </div>

        <form @submit.prevent="updateBranch" enctype="multipart/form-data" v-if="!loading">
          <!-- Error Messages -->
          <div v-if="errors.length" class="mb-4 bg-red-100 text-red-800 p-3 rounded">
            <ul>
              <li v-for="(error, index) in errors" :key="index" class="text-sm">{{ error }}</li>
            </ul>
          </div>

          <!-- LMS Instance Selection -->
          <div class="mb-4">
            <label for="lmsInstance" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">LMS Instance</label>
            <select
              id="lmsInstance"
              v-model="branch.lms_instance_id"
              class="w-full p-3 border rounded-lg dark:bg-gray-700 dark:text-white border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
              required
            >
              <option v-for="instance in lmsInstances" :key="instance.id" :value="instance.id">{{ instance.name }}</option>
            </select>
          </div>

          <!-- Branch Name -->
          <div class="mb-4">
            <label for="name" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Branch Name</label>
            <input
              type="text"
              id="name"
              v-model="branch.name"
              class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
              required
            />
          </div>

          <!-- URL -->
          <div class="mb-4">
            <label for="url" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">URL</label>
            <input
              type="text"
              id="url"
              v-model="branch.url"
              class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
            />
          </div>

          <!-- Theme -->
          <div class="mb-4">
            <label for="theme" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Theme</label>
            <input
              type="text"
              id="theme"
              v-model="branch.theme"
              class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
            />
          </div>

          <!-- Logo Display and Replace Option -->
          <div class="mb-4">
            <label class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Logo</label>
            <div class="mb-4 flex items-center">
              <img :src="logoPreview || branchLogoUrl" alt="Branch Logo" class="h-16 rounded-lg mr-4" v-if="logoPreview || branchLogoUrl" />
              <button type="button" v-if="logoPreview || branchLogoUrl" @click="triggerLogoInput" class="bg-blue-500 text-white px-3 py-1 rounded-lg">Replace Logo</button>
              <button type="button" v-else @click="triggerLogoInput" class="bg-blue-500 text-white px-3 py-1 rounded-lg">Upload Logo</button>
              <input type="file" ref="logoInput" @change="handleLogoUpload" class="hidden" accept="image/*" />
            </div>
          </div>

          <!-- Language -->
          <div class="mb-4">
            <label for="language" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Language</label>
            <select
              id="language"
              v-model="branch.language"
              class="w-full p-3 border rounded-lg dark:bg-gray-700 dark:text-white border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
            >
              <option value="en">English</option>
              <option value="fr">French</option>
              <option value="es">Spanish</option>
            </select>
          </div>

          <!-- Default Branch Checkbox -->
          <div class="mb-4 flex items-center">
            <input
              type="checkbox"
              id="isDefault"
              v-model="branch.is_default"
              class="rounded text-primary-600 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="isDefault" class="ml-2 text-sm font-semibold text-gray-700 dark:text-gray-400">Set as Default Branch</label>
          </div>

          <!-- Submit Button -->
          <div class="mt-6">
            <button
              type="submit"
              :disabled="isSubmitting"
              class="w-full !bg-secondary-600 text-white font-semibold py-3 rounded-lg hover:!bg-secondary-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 flex items-center justify-center"
            >
              <svg v-if="isSubmitting" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
              {{ isSubmitting ? 'Updating...' : 'Update Branch' }}
            </button>
          </div>
        </form>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/SideMenu.vue';

export default {
  components: {
    TopNav,
    SideMenu,
  },
  data() {
    return {
      branch: {
        lms_instance_id: null,
        name: '',
        url: '',
        theme: '',
        language: 'en',
        is_default: false,
        logo_url: null,
      },
      logoFile: null,
      logoPreview: null,
      lmsInstances: [],
      isSubmitting: false,
      loading: true,
      errors: [],
    };
  },
  computed: {
    branchLogoUrl() {
      return this.branch.logo_url ? `${process.env.VUE_APP_API_URL}${this.branch.logo_url}` : null;
    },
  },
  methods: {
    async fetchLmsInstances() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query {
              lmsInstances {
                id
                name
              }
            }
          `,
        });
        this.lmsInstances = response.data.data.lmsInstances;
      } catch (error) {
        console.error("Error fetching LMS instances:", error);
        this.errors.push("Unable to fetch LMS instances.");
      }
    },
    async fetchBranch() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query($id: ID!) {
              branch(id: $id) {
                id
                lms_instance_id
                name
                url
                theme
                language
                is_default
                logo_url
              }
            }
          `,
          variables: { id: this.$route.params.id },
        });
        this.branch = response.data.data.branch;
      } catch (error) {
        console.error("Error fetching branch:", error);
        this.errors.push("Unable to fetch branch details.");
      }
    },
    handleLogoUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.logoFile = file;
        this.logoPreview = URL.createObjectURL(file); // Set temporary preview URL
      }
    },
    triggerLogoInput() {
      this.$refs.logoInput.click();
    },
    async updateBranch() {
      this.isSubmitting = true;
      this.errors = [];

      const formData = new FormData();
      formData.append("operations", JSON.stringify({
        query: `
          mutation UpdateBranch($id: Int!, $input: BranchInput!, $logo: Upload) {
            updateBranch(id: $id, input: $input, logo: $logo) {
              id
              name
              url
              theme
              language
              is_default
              logo_url
            }
          }
        `,
        variables: {
          id: parseInt(this.$route.params.id),
          input: {
            lms_instance_id: parseInt(this.branch.lms_instance_id),
            name: this.branch.name,
            url: this.branch.url,
            theme: this.branch.theme,
            language: this.branch.language,
            is_default: this.branch.is_default,
          },
          logo: this.logoFile ? null : undefined,
        },
      }));

      if (this.logoFile) {
        formData.append("map", JSON.stringify({ "1": ["variables.logo"] }));
        formData.append("1", this.logoFile);
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (response.data.data.updateBranch) {
          this.$router.push({ name: 'ViewBranch', params: { id: this.$route.params.id } });
        }
      } catch (error) {
        console.error("Error updating branch:", error);
        this.errors.push("An error occurred while updating the branch.");
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  async created() {
    this.loading = true;
    await this.fetchLmsInstances();
    await this.fetchBranch();
    this.loading = false;
  },
};
</script>
