<template>
    <!-- Recent Activities -->
    <div class="relative flex flex-col min-w-0 break-words bg-gray-50 dark:bg-gray-800 w-full">
      <div class="rounded-t mb-0 px-0 border-0">
        <div class="flex flex-wrap items-center px-4 py-3">
          <div class="relative w-full max-w-full flex-grow flex-1">
            <h3 class="text-primary-900 font-semibold text-xl dark:text-gray-50">Timeline &amp; Alerts</h3>
          </div>
          <div class="relative w-full max-w-full flex-grow flex-1 text-right">
            <button
              class="bg-blue-500 dark:bg-gray-100 text-white active:bg-blue-600 dark:text-gray-800 dark:active:text-gray-700 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              See all
            </button>
          </div>
        </div>
        <div class="block w-full">
          <div
            v-if="activities && activities.length > 0"
            class="px-4 bg-gray-100 dark:bg-gray-600 text-gray-500 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          >
            Today
          </div>
          <ul v-if="activities && activities.length > 0" class="my-1">
            <li v-for="(activity, index) in activities" :key="index" class="flex px-4">
              <div :class="['w-9 h-9 rounded-full flex-shrink-0 my-2 mr-3', activity.iconBgColor || 'bg-indigo-500']">
                <svg
                  class="w-9 h-9 fill-current"
                  :class="activity.iconColor || 'text-indigo-50'"
                  viewBox="0 0 36 36"
                >
                  <path :d="activity.iconPath"></path>
                </svg>
              </div>
              <div
                class="flex-grow flex items-center border-b border-gray-100 dark:border-gray-400 text-sm text-gray-600 dark:text-gray-100 py-2"
              >
                <div class="flex-grow flex justify-between items-center">
                  <div class="self-center">
                    <span
                      v-html="activity.description"
                      class="font-medium text-gray-800 hover:text-gray-900 dark:text-gray-50 dark:hover:text-gray-100"
                    ></span>
                  </div>
                  <div class="flex-shrink-0 ml-2">
                    <a
                      :href="activity.link || '#0'"
                      class="flex items-center font-medium text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-500"
                    >
                      View
                      <span>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="transform transition-transform duration-500 ease-in-out"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div
            v-else
            class="text-center py-4 text-gray-500 dark:text-gray-400 border border-solid border-gray-200 dark:border-gray-500 rounded-lg"
          >
            No recent activities to display.
          </div>
          <div
            v-if="alerts && alerts.length > 0"
            class="px-4 bg-gray-100 dark:bg-gray-600 text-gray-500 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          >
            Alerts
          </div>
          <ul v-if="alerts && alerts.length > 0" class="my-1">
            <li v-for="(alert, index) in alerts" :key="index" class="flex px-4">
              <div :class="['w-9 h-9 rounded-full flex-shrink-0 my-2 mr-3', alert.iconBgColor || 'bg-green-500']">
                <svg
                  class="w-9 h-9 fill-current"
                  :class="alert.iconColor || 'text-light-blue-50'"
                  viewBox="0 0 36 36"
                >
                  <path :d="alert.iconPath"></path>
                </svg>
              </div>
              <div
                class="flex-grow flex items-center border-b border-gray-100 text-sm text-gray-600 dark:text-gray-50 py-2"
              >
                <div class="flex-grow flex justify-between items-center">
                  <div class="self-center">
                    <span
                      v-html="alert.description"
                      class="font-medium text-gray-800 hover:text-gray-900 dark:text-gray-50 dark:hover:text-gray-100"
                    ></span>
                  </div>
                  <div class="flex-shrink-0 ml-2">
                    <a
                      :href="alert.link || '#0'"
                      class="flex items-center font-medium text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-500"
                    >
                      View
                      <span>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="transform transition-transform duration-500 ease-in-out"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div
            v-else
            class="text-center py-4 text-gray-500 dark:text-gray-400 border border-solid border-gray-200 dark:border-gray-500 rounded-lg"
          >
            No alerts to display.
          </div>
        </div>
      </div>
    </div>
    <!-- ./Recent Activities -->
  </template>
  
  <script>
  export default {
    props: {
      activities: {
        type: Array,
        default: () => [],
      },
      alerts: {
        type: Array,
        default: () => [],
      },
    },
  };
  </script>
  