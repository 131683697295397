<template>
    <nav
        class="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50">
        <div class="flex flex-wrap justify-between items-center">
            <div class="flex justify-start items-center">
                <button data-drawer-target="drawer-navigation" data-drawer-toggle="drawer-navigation"
                    aria-controls="drawer-navigation"
                    class="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <svg aria-hidden="true" class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="sr-only">Toggle sidebar</span>
                </button>
                <a href="/" class="flex items-center justify-between mr-4">
                    <img v-if="branch && branch.logo_url" :src="logoUrl" alt="Branch Logo" class="h-12" />
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="234.284" height="48.459"
                        viewBox="0 0 234.284 48.459">
                        viewBox="0 0 234.284 48.459">
                        <g id="Group_482" data-name="Group 482" transform="translate(-55 139)">
                            <path id="Path_1753" data-name="Path 1753"
                                d="M491.9,1.256A4.38,4.38,0,0,0,488.635,0H343.71V4.295h5.556a1.815,1.815,0,0,1,1.3.467,2.047,2.047,0,0,1,.55,1.533V20.231a3.434,3.434,0,0,0,.923,2.461,3.117,3.117,0,0,0,2.412.97h1.311V24.79h-1.311a3.119,3.119,0,0,0-2.412.973,3.438,3.438,0,0,0-.921,2.461V42.16a2.043,2.043,0,0,1-.55,1.533,1.815,1.815,0,0,1-1.3.467h-5.556v4.295H488.635a4.308,4.308,0,0,0,4.52-4.52V4.52A4.371,4.371,0,0,0,491.9,1.256"
                                transform="translate(-203.87 -139)" fill="#f3374b"></path>
                            <path id="Path_1771" data-name="Path 1771"
                                d="M909.31,49.96h.807v2.108h.407V49.96h.807v-.37H909.31Z"
                                transform="translate(-629.86 -176.349)" fill="#fff"></path>
                            <path id="Path_1772" data-name="Path 1772"
                                d="M921.09,49.59l-.938,1.543-.938-1.543h-.284v2.478h.407V50.481l.674,1.106h.284l.671-1.106v1.587h.407V49.59Z"
                                transform="translate(-637.105 -176.349)" fill="#fff"></path>
                            <path id="Path_1773" data-name="Path 1773"
                                d="M74.067,74.47a8.467,8.467,0,0,1-6-2.066,7.493,7.493,0,0,1-2.224-5.778V63.954h4.621v2.671a3.518,3.518,0,0,0,.911,2.574,3.416,3.416,0,0,0,2.557.928,3.138,3.138,0,0,0,2.4-.911,3.632,3.632,0,0,0,.857-2.592V53.671h-4.2v-4.2H84.607v4.2h-2.8V66.625a7.1,7.1,0,0,1-7.738,7.844"
                                transform="translate(5.412 -176.259)" fill="#f3374b"></path>
                            <path id="Path_1774" data-name="Path 1774"
                                d="M150.008,64.325h6.583l-2.977-11.939h-.629Zm-7.178,9.664,6.442-24.508h8.054l6.442,24.508h-4.761l-1.33-5.393h-8.753l-1.33,5.393H142.83Z"
                                transform="translate(-52.574 -176.267)" fill="#f3374b"></path>
                            <path id="Path_1775" data-name="Path 1775"
                                d="M237.41,73.981V49.47h8.58l4.236,21.359h.629l4.236-21.359h8.58V73.981h-4.482V52.866h-.629l-4.2,21.114h-7.635l-4.2-21.114h-.629V73.981Z"
                                transform="translate(-123.809 -176.259)" fill="#f3374b"></path>
                            <path id="Path_1776" data-name="Path 1776"
                                d="M7.358,24.792V23.664H8.669a3.119,3.119,0,0,0,2.412-.973A3.438,3.438,0,0,0,12,20.231V6.3a1.759,1.759,0,0,1,1.854-2h5.556V0H11.535A4.3,4.3,0,0,0,7.018,4.52V19.833a1.734,1.734,0,0,1-1.854,1.952H0v4.887H5.161a1.734,1.734,0,0,1,1.854,1.952V43.939a4.3,4.3,0,0,0,4.517,4.52h7.879V44.164H13.855a1.758,1.758,0,0,1-1.854-2V28.226a3.434,3.434,0,0,0-.923-2.461,3.117,3.117,0,0,0-2.412-.97H7.356Z"
                                transform="translate(55 -139)" fill="#f3374b"></path>
                            <path id="Path_1777" data-name="Path 1777"
                                d="M422.7,73.106a10.021,10.021,0,0,1-7.213-2.537q-2.662-2.536-2.661-7.264V57.422q0-4.728,2.661-7.264A10.025,10.025,0,0,1,422.7,47.62c3.034,0,5.339.822,6.985,2.468a9.155,9.155,0,0,1,2.468,6.776v.21h-4.552v-.351a5.277,5.277,0,0,0-1.207-3.572,4.618,4.618,0,0,0-3.7-1.4,4.96,4.96,0,0,0-3.851,1.506,5.784,5.784,0,0,0-1.4,4.1v6.023a5.79,5.79,0,0,0,1.4,4.08,4.972,4.972,0,0,0,3.851,1.523,4.623,4.623,0,0,0,3.7-1.417,5.293,5.293,0,0,0,1.207-3.554V63.38h4.552v.491a9.155,9.155,0,0,1-2.468,6.776,9.445,9.445,0,0,1-6.985,2.468"
                                transform="translate(-255.929 -174.866)" fill="#fff"></path>
                            <path id="Path_1778" data-name="Path 1778"
                                d="M514.263,68.974a5.291,5.291,0,0,0,4.026-1.506,5.586,5.586,0,0,0,1.437-4.026V57.279a5.586,5.586,0,0,0-1.437-4.026,6.112,6.112,0,0,0-8.035,0,5.547,5.547,0,0,0-1.454,4.026v6.163a5.543,5.543,0,0,0,1.454,4.026,5.306,5.306,0,0,0,4.009,1.506m0,4.132a10.381,10.381,0,0,1-7.353-2.537q-2.732-2.536-2.73-7.264V57.422q0-4.728,2.73-7.264a11.923,11.923,0,0,1,14.707,0q2.732,2.54,2.732,7.264V63.3q0,4.728-2.732,7.264a10.362,10.362,0,0,1-7.353,2.537"
                                transform="translate(-324.73 -174.866)" fill="#fff"></path>
                            <path id="Path_1779" data-name="Path 1779"
                                d="M604.232,69.9h2.31a5.193,5.193,0,0,0,4.1-1.471,6.043,6.043,0,0,0,1.3-4.132v-4.9a6.035,6.035,0,0,0-1.3-4.149,5.231,5.231,0,0,0-4.1-1.454h-2.31V69.9Zm-7.842,4.2v-4.06h3.221V53.65H596.39V49.59h10.083q4.935,0,7.511,2.5t2.572,7.44v4.623q0,4.935-2.572,7.44t-7.511,2.5Z"
                                transform="translate(-394.18 -176.349)" fill="#fff"></path>
                            <rect id="Rectangle_314" data-name="Rectangle 314" width="4.621" height="24.508"
                                transform="translate(226.228 -126.759)" fill="#fff"></rect>
                            <path id="Path_1780" data-name="Path 1780"
                                d="M731.14,74.1V49.59h8.787l4.868,21.356h.629V49.59h4.552V74.1h-8.787L736.321,52.74h-.629V74.1Z"
                                transform="translate(-495.668 -176.349)" fill="#fff"></path>
                            <path id="Path_1781" data-name="Path 1781"
                                d="M832.934,73.1a9.253,9.253,0,0,1-4.569-1.138,8.274,8.274,0,0,1-3.256-3.327,10.915,10.915,0,0,1-1.209-5.339V57.412q0-4.728,2.661-7.264a10.025,10.025,0,0,1,7.213-2.537c3.034,0,5.339.8,6.985,2.4a8.635,8.635,0,0,1,2.468,6.494v.141h-4.552v-.281a5.011,5.011,0,0,0-.543-2.345,4,4,0,0,0-1.627-1.664,5.509,5.509,0,0,0-2.73-.612,5,5,0,0,0-3.851,1.506,5.783,5.783,0,0,0-1.4,4.1v6.023a5.928,5.928,0,0,0,1.4,4.115,5,5,0,0,0,3.922,1.558,4.615,4.615,0,0,0,3.675-1.33,4.94,4.94,0,0,0,1.155-3.362V64h-5.813V60.075H843.23V72.61h-4.273V70.265h-.629a5.164,5.164,0,0,1-.787,1.261,4.076,4.076,0,0,1-1.627,1.121,7.884,7.884,0,0,1-2.977.454"
                                transform="translate(-565.532 -174.858)" fill="#fff"></path>
                        </g>
                    </svg>
                </a>
                <form action="#" method="GET" class="hidden md:block md:pl-2">
                    <label for="topbar-search" class="sr-only">Search</label>
                    <div class="relative md:w-96">
                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z">
                                </path>
                            </svg>
                        </div>
                        <input type="text" name="course-search" id="topbar-search"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-md focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Search" />
                    </div>
                </form>
            </div>
            <div class="flex items-center lg:order-2">
                <button type="button" data-drawer-toggle="drawer-navigation" aria-controls="drawer-navigation"
                    class="p-2 mr-1 text-gray-500 rounded-lg md:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
                    <span class="sr-only">Toggle search</span>
                    <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd" fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z">
                        </path>
                    </svg>
                </button>
                <!-- Notifications -->
                <button type="button" data-dropdown-toggle="notification-dropdown"
                    class="p-2 mr-1 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
                    <span class="sr-only">View notifications</span>
                    <!-- Bell icon -->
                    <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z">
                        </path>
                    </svg>
                </button>
                <!-- Dropdown menu -->
                <div class="hidden overflow-hidden z-50 my-4 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg dark:divide-gray-600 dark:bg-gray-700 rounded-xl"
                    id="notification-dropdown">
                    <div
                        class="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-600 dark:text-gray-300">
                        Notifications
                    </div>
                    <div>
                        <a href="#"
                            class="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">
                            <div class="flex-shrink-0">

                            </div>
                            <div class="pl-3 w-full">
                                <div class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                                    User completed a course
                                    <span class="font-semibold text-gray-900 dark:text-white">How to write .NET</span>
                                    Click to view their report
                                </div>
                                <div class="text-xs font-medium text-primary-600 dark:text-primary-500">
                                    a few moments ago
                                </div>
                            </div>
                        </a>
                        <a href="#"
                            class="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">

                            <div class="pl-3 w-full">
                                <div class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                                    <span class="font-semibold text-gray-900 dark:text-white">Bill Bobbins</span>
                                    added
                                    a new course "How to become a coder"
                                </div>
                                <div class="text-xs font-medium text-primary-600 dark:text-primary-500">
                                    10 minutes ago
                                </div>
                            </div>
                        </a>
                        <a href="#"
                            class="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">

                            <div class="pl-3 w-full">
                                <div class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                                    <span class="font-semibold text-gray-900 dark:text-white">Simon Jones</span>
                                    subscribed to your course
                                    <span class="font-medium text-gray-900 dark:text-white">Python basics</span>

                                </div>
                                <div class="text-xs font-medium text-primary-600 dark:text-primary-500">
                                    44 minutes ago
                                </div>
                            </div>
                        </a>
                        <a href="#"
                            class="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">

                            <div class="pl-3 w-full">
                                <div class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                                    <span class="font-semibold text-gray-900 dark:text-white">Leslie Livingston</span>
                                    sent you a message
                                    <span class="font-medium text-primary-600 dark:text-primary-500">"Please can you
                                        help me complete this course?"</span>
                                </div>
                                <div class="text-xs font-medium text-primary-600 dark:text-primary-500">
                                    1 hour ago
                                </div>
                            </div>
                        </a>
                        <a href="#" class="flex py-3 px-4 hover:bg-gray-100 dark:hover:bg-gray-600">

                            <div class="pl-3 w-full">
                                <div class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                                    <span class="font-semibold text-gray-900 dark:text-white">Robert Brown</span>
                                    added a new course "Become a coding pro"
                                </div>
                                <div class="text-xs font-medium text-primary-600 dark:text-primary-500">
                                    3 hours ago
                                </div>
                            </div>
                        </a>
                    </div>
                    <a href="#"
                        class="block py-2 text-md font-medium text-center text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-600 dark:text-white dark:hover:underline">
                        <div class="inline-flex items-center">
                            <svg aria-hidden="true" class="mr-2 w-4 h-4 text-gray-500 dark:text-gray-400"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                <path fill-rule="evenodd"
                                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            View all
                        </div>
                    </a>
                </div>
                <!-- Apps -->
                <button type="button" data-dropdown-toggle="apps-dropdown"
                    class="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
                    <span class="sr-only">View notifications</span>
                    <!-- Icon -->
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z">
                        </path>
                    </svg>
                </button>
                <!-- Dropdown menu -->
                <div class="hidden overflow-hidden z-50 my-4 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg dark:bg-gray-700 dark:divide-gray-600 rounded-xl"
                    id="apps-dropdown">
                    <div
                        class="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-600 dark:text-gray-300">
                        Apps
                    </div>
                    <div class="grid grid-cols-3 gap-4 p-4">
                        <a href="#"
                            class="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group">
                            <svg aria-hidden="true"
                                class="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <div class="text-sm text-gray-900 dark:text-white">Dashboard</div>
                        </a>
                        <a href="#"
                            class="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group">
                            <svg aria-hidden="true"
                                class="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z">
                                </path>
                            </svg>
                            <div class="text-sm text-gray-900 dark:text-white">Users</div>
                        </a>
                        <a href="#"
                            class="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group">
                            <svg aria-hidden="true"
                                class="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <div class="text-sm text-gray-900 dark:text-white">Branches</div>
                        </a>
                        <a href="#"
                            class="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group">
                            <svg aria-hidden="true"
                                class="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <div class="text-sm text-gray-900 dark:text-white">
                                Profile
                            </div>
                        </a>
                        <a href="#"
                            class="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group">
                            <svg aria-hidden="true"
                                class="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <div class="text-sm text-gray-900 dark:text-white">
                                Settings
                            </div>
                        </a>
                        <a href="#"
                            class="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group">
                            <svg aria-hidden="true"
                                class="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                                <path fill-rule="evenodd"
                                    d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <div class="text-sm text-gray-900 dark:text-white">
                                Docs
                            </div>
                        </a>
                        <a href="#"
                            class="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group">
                            <svg aria-hidden="true"
                                class="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z"
                                    clip-rule="evenodd" />
                                <path fill-rule="evenodd"
                                    d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
                                    clip-rule="evenodd" />
                                <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                            </svg>

                            <div class="text-sm text-gray-900 dark:text-white">
                                Subscription
                            </div>
                        </a>
                        <a href="#"
                            class="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group">
                            <svg aria-hidden="true"
                                class="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M5 2a2 2 0 00-2 2v14l3.5-2 3.5 2 3.5-2 3.5 2V4a2 2 0 00-2-2H5zm2.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6.207.293a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414zM12.5 10a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <div class="text-sm text-gray-900 dark:text-white">
                                Billing
                            </div>
                        </a>
                        <a href="#"
                            class="block p-4 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 group">
                            <svg aria-hidden="true"
                                class="mx-auto mb-1 w-7 h-7 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-400"
                                fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1">
                                </path>
                            </svg>
                            <div class="text-sm text-gray-900 dark:text-white">
                                Logout
                            </div>
                        </a>
                    </div>
                </div>
                <button type="button"
                    class="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                    id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                    <span class="sr-only">Open user menu</span>
                    <img class="w-9 h-8 rounded-full" src="/img/jamcoding-icon.jpg" alt="user photo" />
                </button>
                <!-- Dropdown menu -->
                <div class="hidden z-50 my-4 w-56 text-base list-none bg-white divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 rounded-xl"
                    id="dropdown">
                    <div class="py-3 px-4">
                        <span class="block text-sm font-semibold text-gray-900 dark:text-white">{{ userName }}</span>
                        <span class="block text-sm text-gray-900 truncate dark:text-white">{{ userEmail }}</span>
                    </div>
                    <ul class="py-1 text-gray-700 dark:text-gray-300" aria-labelledby="dropdown">
                        <li>
                            <a href="#"
                                class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">My
                                profile</a>
                        </li>
                    </ul>
                    <div class="py-3 px-4 pb-0 block text-sm font-semibold text-gray-900">View as</div>
                    <ul class="py-1 text-gray-700 dark:text-gray-300 !border-0" aria-labelledby="dropdown">
                        <li>
                            <a href="/dashboard"
                                class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Administrator</a>
                        </li>
                        <li>
                            <a href="/learner/dashboard"
                                class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Learner</a>
                        </li>
                    </ul>
                    <ul class="py-1 text-gray-700 dark:text-gray-300" aria-labelledby="dropdown">
                        <li>
                            <a href="#"
                                class="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"><svg
                                    class="mr-2 w-5 h-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                My Courses</a>
                        </li>
                        <li>
                            <a href="#"
                                class="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"><svg
                                    class="mr-2 w-5 h-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z">
                                    </path>
                                </svg>
                                Reports</a>
                        </li>
                        <li>
                            <a href="#"
                                class="flex justify-between items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                <span class="flex items-center">
                                    <svg aria-hidden="true" class="mr-2 w-5 h-5 text-primary-600 dark:text-primary-500"
                                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                    Upgrade membership
                                </span>
                            </a>
                        </li>
                    </ul>
                    <ul class="py-1 text-gray-700 dark:text-gray-300" aria-labelledby="dropdown">
                        <!-- Sign Out Button -->
                        <li>
                            <a @click.prevent="signOut" href="#"
                                class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                Sign out
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { Dropdown } from 'flowbite';
import { mapGetters } from 'vuex';

export default {


    mounted() {
        // Initialize all dropdowns
        const dropdownElements = document.querySelectorAll('[data-dropdown-toggle]');
        dropdownElements.forEach(triggerEl => {
            const targetEl = document.getElementById(triggerEl.getAttribute('data-dropdown-toggle'));
            new Dropdown(targetEl, triggerEl);
        });
    },
    computed: {
        logoUrl() {
            return this.branch.logo_url ? `${process.env.VUE_APP_API_URL}${this.branch.logo_url}` : null;
        },
        ...mapGetters({
            user: 'getUser',
            branch: 'getBranch',
        }),
        userName() {
            return this.user.first_name + ' ' + this.user.last_name;
        },
        userEmail() {
            return this.user ? this.user.email : '';
        }
    },
    methods: {
        async signOut() {
            try {
                // Optional: make an API call to logout endpoint if needed
                // await axios.post(`${process.env.VUE_APP_API_URL}/logout`);

                // Clear any stored tokens or session information
                localStorage.removeItem("token"); // if using local storage
                sessionStorage.clear(); // or clear session if stored there

                // Redirect to login page
                this.$router.push({ name: "Login" });
            } catch (error) {
                console.error("Error signing out:", error);
            }
        },
    },
}
</script>
