<template>
  <aside class="fixed top-0 left-0 z-1 max-w-sm w-full h-screen pt-14 transition-transform -translate-x-full sm:translate-x-0 bg-white border-r border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidenav" id="drawer-navigation">
    <div class="overflow-y-auto px-3 pt-5 pb-20 h-full bg-gray-200 dark:bg-gray-800">
      <div class="mb-5 flex justify-between items-center">
        <a href="/courses" class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          <span class="ml-2">Back</span>
        </a>

        <button @click="$emit('publish-course')" class="mt-4 mr-4 px-3 py-2 bg-primary-700 text-white rounded-md hover:bg-primary-600 focus:outline-none">
          {{ mode === 'edit' ? 'Update Course' : 'Publish Course' }}
        </button>
      </div>

      <!-- NewCourse Card: Static and not draggable -->
      <div @click="goToNewCourse" class="bg-white rounded-lg shadow-md p-4 mb-8 flex justify-between items-center">
        <div class="flex items-center">
          <div class="flex-shrink-0 h-16 w-16">
            <img
              :src="newCourseThumbnail"
              alt="Course Thumbnail"
              class="h-16 w-16 rounded-md object-cover"
            />
          </div>
          <div class="ml-4">
            <h3 class="text-lg font-semibold">{{ courseTitle || 'New Course' }}</h3>
          </div>
        </div>
        <span class="bg-red-500 text-white px-2 py-1 rounded-md text-sm ml-auto">Draft</span>
      </div>

      <h3 class="text-lg font-semibold mb-2">Course Content</h3>

      <!-- Render draggable only for other units, excluding NewCourse -->
      <draggable v-model="localCards" @end="updateUnitOrder" class="space-y-4">
        <template #item="{ element, index }">
          <div class="relative flex items-center bg-white rounded-lg shadow-md p-4">
            <!-- Card Content (SelectedOptions) -->
            <SelectedOptions 
              :card="element" 
              :index="index"
              @select="selectUnit(index)" 
              @update="updateCardData(index, $event)"
              class="flex-grow"
            />
            <!-- Delete Button Icon -->
            <button
              @click="deleteUnit(index)"
              class="ml-4 text-gray-800 hover:text-red-500 dark:text-white"
              title="Delete Unit"
            >
              <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
              </svg>
            </button>
          </div>
        </template>
      </draggable>
      

      <div v-if="showDropdown" class="fixed bottom-14 left-1/2 transform -translate-x-1/2 w-11/12 max-w-lg mx-auto bg-white rounded-lg shadow-lg py-1">
        <SelectionButton 
          v-for="(button, index) in buttons" 
          :key="index" 
          :label="button.label" 
          :iconPath="button.iconPath" 
          @option-clicked="addCard(button.label)"  
        />
      </div>
    </div>

    <button @click="showDropdown = !showDropdown" class="fixed bottom-0 left-0 right-0 px-4 bg-primary-700 text-white py-3 text-center flex justify-between items-center">
      <span>Add</span>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
      </svg>
    </button>
  </aside>
</template>

<script>
import SelectedOptions from '@/components/AddCourse/SelectedOptions.vue';
import SelectionButton from '@/components/AddCourse/SelectionButtons.vue';
import draggable from 'vuedraggable';

export default {
  components: {
    SelectedOptions,
    SelectionButton,
    draggable,
  },
  computed: {
  newCourseThumbnail() {
    if (!this.thumbnailUrl) {
      // Fallback to default image if thumbnailUrl is undefined or empty
      return "/img/default-course-image.jpg";
    }
    // Handle both relative and absolute paths
    return this.thumbnailUrl.startsWith("/")
      ? `${process.env.VUE_APP_API_URL}${this.thumbnailUrl}`
      : this.thumbnailUrl;
  },
},

  data() {
    return {
      showDropdown: false,
      selectedOption: null,
      localCards: [], // Local copy of cards excluding NewCourse
      optionIcons: {
        Content: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M19 7h1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h11.5M7 14h6m-6 3h6m0-10h.5m-.5 3h.5M7 7h3v3H7V7Z',
          iconFill: 'none',
        },
        Video: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M14 6H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm7 11-6-2V9l6-2v10Z',
          iconFill: 'none',
        },
        Audio: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M10 3v4a1 1 0 0 1-1 1H5m8 7.5V8s3 1 3 4m3-8v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Zm-6 12c0 1.105-1.12 2-2.5 2S8 17.105 8 16s1.12-2 2.5-2 2.5.895 2.5 2Z',
          iconFill: 'none',
        },
        Presentation: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M12 6.03v13m0-13c-2.819-.831-4.715-1.076-8.029-1.023A.99.99 0 0 0 3 6v11c0 .563.466 1.014 1.03 1.007 3.122-.043 5.018.212 7.97 1.023m0-13c2.819-.831 4.715-1.076 8.029-1.023A.99.99 0 0 1 21 6v11c0 .563-.466 1.014-1.03 1.007-3.122-.043-5.018.212-7.97 1.023',
          iconFill: 'none',
        },
        iFrame: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'm8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14',
          iconFill: 'none',
        },
        Test: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z',
          iconFill: 'none',
        },
        Assignments: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M18 5V4a1 1 0 0 0-1-1H8.914a1 1 0 0 0-.707.293L4.293 7.207A1 1 0 0 0 4 7.914V20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5M9 3v4a1 1 0 0 1-1 1H4m11.383.772 2.745 2.746m1.215-3.906a2.089 2.089 0 0 1 0 2.953l-6.65 6.646L9 17.95l.739-3.692 6.646-6.646a2.087 2.087 0 0 1 2.958 0Z',
          iconFill: 'none',
        },
        Scorm: {
          iconClass: 'h-16 w-16 text-gray-600 dark:text-gray-400',
          iconPath: 'M13.5 8H4m0-2v13a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-5.032a1 1 0 0 1-.768-.36l-1.9-2.28a1 1 0 0 0-.768-.36H5a1 1 0 0 0-1 1Z',
          iconFill: 'none',
        },
      },

      buttons: [
        { label: 'Content', iconPath: 'M19 7h1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h11.5M7 14h6m-6 3h6m0-10h.5m-.5 3h.5M7 7h3v3H7V7Z' },
        { label: 'Video', iconPath: 'M14 6H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm7 11-6-2V9l6-2v10Z' },
        { label: 'Audio', iconPath: 'M10 3v4a1 1 0 0 1-1 1H5m8 7.5V8s3 1 3 4m3-8v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Zm-6 12c0 1.105-1.12 2-2.5 2S8 17.105 8 16s1.12-2 2.5-2 2.5.895 2.5 2Z' },
        { label: 'Presentation', iconPath: 'M12 6.03v13m0-13c-2.819-.831-4.715-1.076-8.029-1.023A.99.99 0 0 0 3 6v11c0 .563.466 1.014 1.03 1.007 3.122-.043 5.018.212 7.97 1.023m0-13c2.819-.831 4.715-1.076 8.029-1.023A.99.99 0 0 1 21 6v11c0 .563-.466 1.014-1.03 1.007-3.122-.043-5.018.212-7.97 1.023' },
        { label: 'iFrame', iconPath: 'm8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14' },
        { label: 'Test', iconPath: 'M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z' },
        { label: 'Assignments', iconPath: 'M18 5V4a1 1 0 0 0-1-1H8.914a1 1 0 0 0-.707.293L4.293 7.207A1 1 0 0 0 4 7.914V20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5M9 3v4a1 1 0 0 1-1 1H4m11.383.772 2.745 2.746m1.215-3.906a2.089 2.089 0 0 1 0 2.953l-6.65 6.646L9 17.95l.739-3.692 6.646-6.646a2.087 2.087 0 0 1 2.958 0Z' },
        { label: 'Scorm', iconPath: 'M13.5 8H4m0-2v13a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-5.032a1 1 0 0 1-.768-.36l-1.9-2.28a1 1 0 0 0-.768-.36H5a1 1 0 0 0-1 1Z' },
        ],
    };
  },
  watch: {
    cards: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.localCards = newVal.filter(card => card.type !== 'NewCourse'); // Exclude NewCourse from draggable items
        }
      },
      immediate: true, // Ensure this watch handler runs when the component is created
      deep: true,
    },
  },
  methods: {
    toggleDropdown(option) {
      this.selectedOption = option;
    },
    addCard(option) {
  const iconData = this.optionIcons[option];
  if (iconData) {
    // Ensure unit_order starts at the next available sequential number
    const maxUnitOrder = this.localCards.reduce(
      (max, card) => Math.max(max, card.unit_order),
      0
    );

    const newCard = {
      type: option,
      unit_order: maxUnitOrder + 1, // Assign the next available unit_order
      title: `${option} unit ${maxUnitOrder + 1}`, // Set title based on new unit_order
      iconClass: iconData.iconClass,
      iconPath: iconData.iconPath,
      iconFill: iconData.iconFill,
      data: {},
    };

    this.localCards.push(newCard);
    this.$emit('option-selected', newCard);
    this.showDropdown = false;

    // Reset the unit_order and titles to ensure sequential ordering
    this.resetUnitOrder();
  }
},
    selectUnit(index) {
      const selectedUnit = this.localCards[index];
      this.$emit('unit-selected', selectedUnit);
    },
    goToNewCourse() {
      this.$emit('view-new-course');
    },
    deleteUnit(index) {
  console.log("Deleting unit at index:", index);
  console.log("Current localCards before deletion:", JSON.stringify(this.localCards, null, 2));

  // Remove the unit from localCards
  const deletedUnit = this.localCards.splice(index, 1)[0];
  console.log("Deleted unit:", deletedUnit);
  console.log("localCards after deletion:", JSON.stringify(this.localCards, null, 2));

  // Determine the new selected unit after deletion
  let newSelectedUnit = null;

  if (this.localCards.length > 0) {
    // If there are still units left, select the next or previous unit
    if (index < this.localCards.length) {
      newSelectedUnit = this.localCards[index]; // Select the next unit
    } else {
      newSelectedUnit = this.localCards[index - 1]; // Select the previous unit
    }
  }

  console.log("New selected unit calculated:", newSelectedUnit);

  // Emit the new selected unit to the parent
  if (newSelectedUnit) {
    this.$emit("unit-selected", newSelectedUnit);
  } else {
    console.log("No units left, falling back to NewCourse.");
    this.goToNewCourse(); // Fall back to NewCourse if no units remain
  }

  // Reset unit order after deletion
  this.resetUnitOrder();
},
resetUnitOrder() {
  // Reset unit_order values for localCards sequentially
  this.localCards.forEach((card, index) => {
    card.unit_order = index + 1; // Start from 1
    // Preserve existing title if it exists
    card.title = card.title && !card.title.startsWith(`${card.type} unit`)
      ? card.title
      : `${card.type} unit ${index + 1}`; // Only set default title if needed
  });

  // Update courseData.units, ensuring NewCourse remains at 0
  const newCourseUnit = this.$parent.courseData.units.find(
    (unit) => unit.type === "NewCourse"
  );
  const updatedUnits = this.localCards.map((card, index) => ({
    ...card,
    unit_order: index + 1,
    // Preserve existing title if it exists
    title: card.title && !card.title.startsWith(`${card.type} unit`)
      ? card.title
      : `${card.type} unit ${index + 1}`,
  }));

  this.$parent.courseData.units = [
    { ...newCourseUnit, unit_order: 0 }, // Keep NewCourse at 0
    ...updatedUnits,
  ];

  console.log("Unit orders and titles reset. Updated courseData.units:", this.$parent.courseData.units);

  // Emit the updated unit order to the parent component
  this.$emit("unit-order-updated", this.localCards);
},
    updateUnitOrder() {
      // Update unit_order for each card based on its new position
      this.localCards.forEach((card, index) => {
        card.unit_order = index + 1;
      });

      // Sync the unit_order in the corresponding data arrays
      this.syncUnitOrders();

      // Emit the updated localCards array to the parent component
      this.$emit('unit-order-updated', this.localCards);
    },
    syncUnitOrders() {
      // Sync the unit_order in the specific unit type arrays with localCards
      this.localCards.forEach(card => {
        const unitArrayName = this.getUnitArrayName(card.type);
        if (unitArrayName && this.$parent.courseData[unitArrayName]) {
          const unit = this.$parent.courseData[unitArrayName].find(u => u.unit_order === card.unit_order);
          if (unit) {
            unit.unit_order = card.unit_order;
          }
        }
      });
    },
    getUnitArrayName(type) {
      switch (type) {
        case 'Content':
          return 'content';
        case 'Video':
          return 'video';
        case 'Audio':
          return 'audio';
        case 'Presentation':
          return 'document';
        case 'iFrame':
          return 'iframe';
        case 'Test':
          return 'test';
        case 'Assignments':
          return 'assignment';
        case 'Scorm':
          return 'scorm';
        default:
          return null;
      }
    },
    updateCardData(index, updatedData) {
      this.$set(this.localCards, index, updatedData);
      this.$emit('unit-data-updated', this.localCards);
    },
  },
  props: {
    courseTitle: {
      type: String,
      required: true,
    },
    cards: {
      type: Array,
      required: true,
    },
    mode: {
          type: String,
          default: 'add',
    },
    thumbnailUrl: {
      type: String,
      default: "/img/default-course-image.jpg",
    },
  },
};
</script>