<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen relative">
    <TopNav />
    <SideMenu />

    <!-- Page Loading Spinner -->


    <main class="p-6 md:ml-64 h-auto pt-20">
      <div v-if="loading" class="text-center py-10">

        <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span class="sr-only">Loading...</span>
                  </div>
      
      <section v-else class="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
        <div class="mx-auto max-w-screen-3xl px-4 lg:px-12">
          <!-- Summary Overview -->
          <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10">
            <!-- Total Courses -->
            <div class="p-6 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-lg shadow-lg">
              <h2 class="text-lg font-semibold">Courses Assigned</h2>
              <p class="text-4xl font-bold">{{ summary.totalCourses }}</p>
              <div class="mt-2 text-sm opacity-75">Manage all your assigned courses</div>
            </div>
            <!-- Completed Courses -->
            <div class="p-6 bg-gradient-to-r from-green-500 to-green-700 text-white rounded-lg shadow-lg">
              <h2 class="text-lg font-semibold">Courses Completed</h2>
              <p class="text-4xl font-bold">{{ summary.completedCourses }}</p>
              <div class="mt-2 text-sm opacity-75">Keep track of your achievements</div>
            </div>
            <!-- Completion Rate -->
            <div class="p-6 bg-gradient-to-r from-purple-500 to-purple-700 text-white rounded-lg shadow-lg">
              <h2 class="text-lg font-semibold">Completion Rate</h2>
              <p class="text-4xl font-bold">{{ summary.completionRate }}%</p>
              <div class="mt-2 text-sm opacity-75">Your overall progress rate</div>
            </div>
          </div>

          <!-- Progress Charts -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
            <!-- Course Progress Chart -->
            <div class="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-screen-lg">
              <h2 class="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-3">Course Progress</h2>
              <canvas v-if="courses.length > 0" id="progressBarChart" class="max-h-96"></canvas>
              <p v-else class="text-center text-gray-600 dark:text-gray-400">No courses available for progress data.</p>
            </div>
            <!-- Course Status Chart -->
            <div class="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-screen-lg">
              <h2 class="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-3">Course Status</h2>
              <canvas v-if="summary.totalCourses > 0" id="statusPieChart" class="max-h-96"></canvas>
              <p v-else class="text-center text-gray-600 dark:text-gray-400">No course status data available.</p>
            </div>
          </div>

          <!-- Course Details Table -->
          <div class="overflow-x-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg">
            <table v-if="courses.length > 0" class="w-full text-md text-left text-gray-600 dark:text-gray-300">
              <thead class="text-md bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300">
                <tr>
                  <th class="px-4 py-3">Status</th>
                  <th class="px-4 py-3">Course Name</th>
                  <th class="px-4 py-3">Progress</th>
                  <th class="px-4 py-3">Units Completed</th>
                  <th class="px-4 py-3">Time Spent</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="course in courses"
                  :key="course.id"
                  class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <td class="px-4 py-3 text-center">
                    <span :class="statusMarkerClass(course.completionPercentage)" class="inline-block w-4 h-4 rounded-full"></span>
                  </td>
                  <td class="px-4 py-3">{{ course.title }}</td>
                  <td class="px-4 py-3">
                    <div class="relative w-full h-4 bg-gray-200 dark:bg-gray-600 rounded-full overflow-hidden">
                      <div class="h-full bg-blue-500 dark:bg-blue-400" :style="{ width: course.completionPercentage + '%' }"></div>
                    </div>
                    <span class="text-sm text-gray-500 dark:text-gray-300">{{ course.completionPercentage }}%</span>
                  </td>
                  <td class="px-4 py-3">{{ course.unitsCompleted }}/{{ course.totalUnits }}</td>
                  <td class="px-4 py-3">{{ formatTime(course.timeSpent) }}</td>
                </tr>
              </tbody>
            </table>
            <p v-else class="text-center text-gray-600 dark:text-gray-400 p-6">No enrolled courses available.</p>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>


<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/LearnerSideMenu.vue';
import { Chart } from 'chart.js/auto';
import { nextTick } from 'vue';

export default {
  components: { TopNav, SideMenu },
  data() {
    return {
      summary: {
        totalCourses: 0,
        completedCourses: 0,
        completionRate: 0,
      },
      courses: [],
      loading: true,
    };
  },
  methods: {
    async fetchProgressData() {
      this.loading = true;
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query {
              UserProgress {
                totalCourses
                completedCourses
                averageCompletionRate
                courses {
                  id
                  title
                  completionPercentage
                  unitsCompleted
                  totalUnits
                  timeSpent
                }
              }
            }
          `,
        });
        const data = response.data.data.UserProgress;
        this.summary = {
          totalCourses: data.totalCourses,
          completedCourses: data.completedCourses,
          completionRate: Math.round(data.averageCompletionRate),
        };
        this.courses = data.courses;
      } catch (error) {
        console.error('Error fetching progress data:', error);
      } finally {
        this.loading = false;

        // Ensure charts initialize after DOM is updated
        nextTick(() => {
          this.initializeCharts();
        });
      }
    },
    initializeCharts() {
      const progressCanvas = document.getElementById('progressBarChart');
      const statusCanvas = document.getElementById('statusPieChart');

      if (progressCanvas) {
        const progressChart = progressCanvas.getContext('2d');

        // Limit to the latest 5 courses for the progress chart
        const latestCourses = this.courses.slice(0, 5);

        // Course Progress Chart - Vertical Bar
        new Chart(progressChart, {
          type: 'bar',
          data: {
            labels: latestCourses.map(course => course.title),
            datasets: [
              {
                label: 'Completion (%)',
                data: latestCourses.map(course => course.completionPercentage),
                backgroundColor: '#4CAF50',
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: (context) => `${context.raw}%`,
                },
              },
            },
            scales: {
              x: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Courses',
                  color: '#666',
                },
              },
              y: {
                beginAtZero: true,
                max: 100,
                title: {
                  display: true,
                  text: 'Completion (%)',
                  color: '#666',
                },
              },
            },
          },
        });
      }

      if (statusCanvas) {
        const statusChart = statusCanvas.getContext('2d');

        // Course Status Chart - Pie Chart
        const notStartedCount =
          this.summary.totalCourses -
          this.summary.completedCourses -
          this.courses.filter(course => course.completionPercentage > 0 && course.completionPercentage < 100).length;

        new Chart(statusChart, {
          type: 'pie',
          data: {
            labels: ['Completed', 'In Progress', 'Not Started'],
            datasets: [
              {
                data: [
                  this.summary.completedCourses,
                  this.courses.filter(course => course.completionPercentage > 0 && course.completionPercentage < 100).length,
                  notStartedCount > 0 ? notStartedCount : 0,
                ],
                backgroundColor: ['#4CAF50', '#FFC107', '#F44336'],
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => `${context.label}: ${context.raw}`,
                },
              },
            },
          },
        });
      }
    },
    statusMarkerClass(completionPercentage) {
      if (completionPercentage === 0) return 'bg-gray-400';
      if (completionPercentage < 100) return 'bg-yellow-500';
      return 'bg-green-500';
    },
    formatTime(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      return `${hrs}h ${mins}m`;
    },
  },
  created() {
    this.fetchProgressData();
  },
};
</script>
