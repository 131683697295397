<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
    <TopNav />
    <SideMenu />

    <main class="p-6 md:ml-64 h-auto pt-20 flex justify-center">
      <section class="w-full max-w-lg dark:bg-gray-800 rounded-lg p-8">
        <div class="mb-6 flex justify-between items-center">
          <h2 class="text-3xl font-semibold text-gray-800 dark:text-gray-100">Create A New Branch</h2>
          <a href="/branches" class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span class="ml-2">Back</span>
          </a>
        </div>

        <form @submit.prevent="createBranch" enctype="multipart/form-data">
          <!-- Error Messages -->
          <div v-if="errors.length" class="mb-4 bg-red-100 text-red-800 p-3 rounded">
            <ul>
              <li v-for="(error, index) in errors" :key="index" class="text-sm">{{ error }}</li>
            </ul>
          </div>

          <!-- LMS Instance Selection -->
          <div class="mb-4">
            <label for="lmsInstance" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">LMS Instance</label>
            <select
              id="lmsInstance"
              v-model="branch.lms_instance_id"
              class="w-full p-3 border rounded-lg dark:bg-gray-700 dark:text-white border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
              required
            >
              <option v-for="instance in lmsInstances" :key="instance.id" :value="instance.id">{{ instance.name }}</option>
            </select>
          </div>

          <!-- Branch Name -->
          <div class="mb-4">
            <label for="name" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Branch Name</label>
            <input
              type="text"
              id="name"
              v-model="branch.name"
              class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
              required
            />
          </div>

          <!-- URL -->
          <div class="mb-4">
            <label for="url" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">URL</label>
            <input
              type="text"
              id="url"
              v-model="branch.url"
              class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
            />
          </div>

          <!-- Theme -->
          <div class="mb-4">
            <label for="theme" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Theme</label>
            <input
              type="text"
              id="theme"
              v-model="branch.theme"
              class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
            />
          </div>

          <!-- Logo File Upload -->
          <div class="mb-4">
            <label for="logoFile" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Logo</label>
            <input
              type="file"
              id="logoFile"
              @change="handleLogoUpload"
              class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
              accept="image/*"
            />
          </div>

          <!-- Language -->
          <div class="mb-4">
            <label for="language" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Language</label>
            <select
              id="language"
              v-model="branch.language"
              class="w-full p-3 border rounded-lg dark:bg-gray-700 dark:text-white border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
            >
              <option value="en">English</option>
              <option value="fr">French</option>
              <option value="es">Spanish</option>
            </select>
          </div>

          <!-- Default Branch Checkbox -->
          <div class="mb-4 flex items-center">
            <input
              type="checkbox"
              id="isDefault"
              v-model="branch.is_default"
              class="rounded text-primary-600 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="isDefault" class="ml-2 text-sm font-semibold text-gray-700 dark:text-gray-400">Set as Default Branch</label>
          </div>

          <!-- Submit Button -->
          <div class="mt-6">
            <button
              type="submit"
              :disabled="isSubmitting"
              class="w-full !bg-secondary-600 text-white font-semibold py-3 rounded-lg hover:!bg-secondary-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 flex items-center justify-center"
            >
              <svg v-if="isSubmitting" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
              {{ isSubmitting ? 'Creating...' : 'Create Branch' }}
            </button>
          </div>
        </form>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/SideMenu.vue';

export default {
  components: {
    TopNav,
    SideMenu,
  },
  data() {
    return {
      branch: {
        lms_instance_id: null,
        name: '',
        url: '',
        theme: '',
        language: 'en',
        is_default: false,
      },
      logoFile: null, // File object for logo
      lmsInstances: [],
      isSubmitting: false,
      errors: [],
    };
  },
  methods: {
    async fetchLmsInstances() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query {
              lmsInstances {
                id
                name
              }
            }
          `,
        });
        this.lmsInstances = response.data.data.lmsInstances;
      } catch (error) {
        console.error("Error fetching LMS instances:", error);
        this.errors.push("Unable to fetch LMS instances.");
      }
    },
    handleLogoUpload(event) {
      this.logoFile = event.target.files[0];
    },
async createBranch() {
  this.isSubmitting = true;
  this.errors = [];

  const formData = new FormData();
  formData.append("operations", JSON.stringify({
    query: `
      mutation CreateBranch($input: BranchInput!, $logo: Upload) {
        createBranch(input: $input, logo: $logo) {
          id
          name
          logo_url
        }
      }
    `,
    variables: {
      input: {
        lms_instance_id: parseInt(this.branch.lms_instance_id),
        name: this.branch.name,
        url: this.branch.url,
        theme: this.branch.theme,
        language: this.branch.language,
        is_default: this.branch.is_default,
      },
      logo: null,
    },
  }));

  formData.append("map", JSON.stringify({ "1": ["variables.logo"] }));
  formData.append("1", this.logoFile);

  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    
    if (response.data.data.createBranch) {
      this.$router.push({ name: 'ViewBranch', params: { id: response.data.data.createBranch.id } });
    }
  } catch (error) {
    console.error("Error creating branch:", error);
  } finally {
    this.isSubmitting = false;
  }
}



  },
  created() {
    this.fetchLmsInstances();
  },
};
</script>
