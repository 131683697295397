<template>
    <div v-if="show" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-xl w-full p-6">
        <h2 class="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-6 text-center">{{ title }}</h2>
  
        <!-- Search bar -->
        <input
          v-model="searchQuery"
          @input="fetchItems"
          type="text"
          placeholder="Search..."
          class="w-full p-3 border rounded-lg dark:bg-gray-700 dark:text-white mb-4 focus:outline-none focus:ring-2 focus:ring-primary-500"
        />
  
        <!-- Loading Spinner -->
        <div v-if="loading" class="text-center py-10">
          <svg aria-hidden="true" class="w-10 h-10 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
  
        <!-- Items Table -->
        <div v-if="!loading && items && items.length > 0" class="overflow-auto max-h-64">
          <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead class="bg-gray-100 dark:bg-gray-700">
              <tr>
                <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Select</th>
                <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  {{ itemsEndpoint === 'users' ? 'Name' : 'Title' }}
                </th>
                <th v-if="itemsEndpoint === 'users'" class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Email</th>
              </tr>
            </thead>
            <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              <tr v-for="item in items" :key="item.id">
                <td class="px-4 py-4 whitespace-nowrap text-left">
                  <input type="checkbox" :value="item.id" v-model="selectedItems" class="rounded text-primary-600 focus:ring-primary-500">
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-gray-800 dark:text-gray-100">
                  {{ itemsEndpoint === 'users' ? `${item.first_name} ${item.last_name}` : item.title }}
                </td>
                <td v-if="itemsEndpoint === 'users'" class="px-4 py-4 whitespace-nowrap text-gray-800 dark:text-gray-100">{{ item.email }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p v-else-if="!loading && items.length === 0" class="text-center text-gray-600 dark:text-gray-400 mt-4">No results found.</p>
  
        <!-- Action Buttons -->
        <div class="mt-6 flex justify-end space-x-4">
          <button @click="close" class="px-5 py-1 bg-gray-500 text-white rounded-lg hover:bg-gray-600">Cancel</button>
          <button @click="addItems" class="px-5 py-1 bg-primary-700 text-white rounded-lg hover:bg-blue-700">Add</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    props: {
      show: Boolean,
      title: String,
      itemsEndpoint: String, // Determines the type of items: 'users' or 'courses'
      branchItems: {
        type: Array,
        default: () => [],
      },
      branchId: {
        type: Number,
        required: true,
      },
    },
  
    data() {
      return {
        items: [],
        selectedItems: [],
        searchQuery: "",
        loading: false,
      };
    },
  
    watch: {
      show(newVal) {
        if (newVal) this.fetchItems();
      },
      searchQuery() {
        this.fetchItems();
      },
    },
  
    methods: {
      async fetchItems() {
        this.loading = true; // Set loading to true when starting the fetch
        this.items = []; // Reset items to avoid showing stale data
        const query = this.itemsEndpoint === "users"
          ? `
            query($search: String) {
              users(search: $search) {
                id
                first_name
                last_name
                email
              }
            }
          `
          : `
            query($search: String) {
              courses(search: $search) {
                id
                title
              }
            }
          `;
  
        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
            query,
            variables: { search: this.searchQuery },
          });
  
          const fetchedItems = response.data.data[this.itemsEndpoint];
  
          // Filter out items already added to the branch
          this.items = fetchedItems.filter(item =>
            !this.branchItems.some(branchItem => branchItem.id === item.id)
          );
        } catch (error) {
          console.error(`Error fetching ${this.itemsEndpoint}:`, error);
        } finally {
          this.loading = false; // Ensure loading is set to false after fetching completes
        }
      },
  
      async addItems() {
  const mutation = this.itemsEndpoint === "users"
    ? `
      mutation ($userBranchInput: UserBranchInput!) {
        addUserToBranch(userBranchInput: $userBranchInput) {
          id
          name
          users {
            id
            first_name
            last_name
          }
        }
      }
    `
    : `
      mutation ($branchCoursesInput: BranchCoursesInput!) {
        assignCoursesToBranch(branchCoursesInput: $branchCoursesInput) {
          id
          name
          courses {
            id
            title
          }
        }
      }
    `;

  const variables = this.itemsEndpoint === "users"
    ? {
        userBranchInput: {
          user_ids: this.selectedItems.map(Number),
          branch_id: Number(this.branchId),
          role: "learner",
        },
      }
    : {
        branchCoursesInput: {  // Ensuring branchCoursesInput matches the expected argument name
          branch_id: Number(this.branchId),
          course_ids: this.selectedItems.map(Number),
        },
      };

  try {
    this.loading = true;
    await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
      query: mutation,
      variables,
    });
    this.$emit("refreshBranchData");
    this.close();
  } catch (error) {
    console.error(`Error adding ${this.itemsEndpoint} to branch:`, error);
  } finally {
    this.loading = false;
  }
},
      close() {
        this.selectedItems = [];
        this.$emit("close");
      },
    },
  };
  </script>
  
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  